<template>
  <div>
    <b-card>
      <b-row align-h="between">
        <b-col
          md="1"
          class="mb-2"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="[10, 15, 20]"
          />
        </b-col>
        <b-col
          md="8"
          class="my-1"
        >
          <b-form-input
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Pesquisar"
          />
        </b-col>
      </b-row>

      <b-col cols="12">
        <b-table
          striped
          responsive
          small
          hover
          :per-page="perPage"
          :current-page="currentPage"
          :items="clientes"
          :fields="fieldsCliente"
          :filter="filter"
          @row-clicked="rowClick"
        >
          <template #cell(status_cliente_id)="data">
            <div
              v-for="obj in data.item.status_cliente_id"
              :key="obj.title"
            >
              <feather-icon
                icon="CircleIcon"
                size="8"
              />
              {{ obj.title }}
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
        />
      </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BTable,
  BFormSelect,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'
// import routes from '@/router/routes/cobranca'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BFormSelect,
    BFormInput,
    BPagination,
  },
  data() {
    return {
      filter: '',
      fieldsCliente: [
        { key: 'nome', sortable: true },
        { key: 'cpf_cnpj', label: 'cpf/cnpj', sortable: true },
        { key: 'status_cliente_id', label: 'status', sortable: true },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      clientes: [],
    }
  },
  async created() {
    await axios.get('api/v1/credor/clientes', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    }).then(res => {
      // this.clientes = []
      res.data.dados.map(dt => {
        this.clientes.push({
          id: dt.id,
          nome: dt.nome,
          cpf_cnpj: this.cpfCNPJ(dt.cpf_cnpj),
          status_cliente_id: dt.status_cliente_id,
        })
      })
    })
    this.totalRows = this.clientes.length
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.id } })
    },
  },
}
</script>
